<template>
  <div class="card shadow-sm">
    <form id="formItems" class="needs-validation" novalidate>
      <div class="card-header">
        <h3 class="card-title" style="font-size: 20px; font-weight: bold">
          เพิ่มผู้ใช้งาน
        </h3>
      </div>
      <!-- form -->
      <div class="card-body">
        <!-- -->

        <div class="row d-flex justify-content-center mb-4">
          <label
            for="secondaryProductCode"
            class="required form-label col-sm-10"
            >UUID</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.uuid"
              type="text"
              class="form-control"
              name="secondaryProductCode"
              id="secondaryProductCode"
              required
            />
          </div>
        </div>
        <!-- <div class="row d-flex justify-content-center mb-4">
          <label
            for="secondaryProductName"
            class="required form-label col-sm-10"
            >password</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.password"
              type="password"
              class="form-control"
              name="secondaryProductName"
              id="secondaryProductName"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div> -->
        <div class="row d-flex justify-content-center mb-4">
          <label
            for="secondaryProductCode"
            class="required form-label col-sm-10"
            >Name</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.name"
              type="text"
              class="form-control"
              name="secondaryProductCode"
              id="secondaryProductCode"
              required
            />
          </div>
        </div>

        <div class="row d-flex justify-content-center mb-4">
          <div class="col-sm-10">
            <label
              for="secondaryProductCode"
              class="required form-label col-sm-10"
              >เลือกบริษัท</label
            >
            <select
              v-model="form.companyId"
              class="form-select fadeIn fifth"
              aria-label="Default select example"
            >
              <option v-for="item in companyItems" :key="item" :value="item.id">
                <!-- {{ item.company_code }} - {{ item.company_name }} -->
                {{ item.company_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="row d-flex justify-content-center mb-4">
          <div class="col-sm-10">
            <label
              for="secondaryProductCode"
              class="required form-label col-sm-10"
              >เลือกสาขา</label
            >
            <select
              v-model="form.branchId"
              class="form-select fadeIn fifth"
              aria-label="Default select example"
            >
              <option v-for="item in branchItems" :key="item" :value="item.id">
                {{ item.code }} - {{ item.short_name }} - {{ item.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="row d-flex justify-content-center mb-4">
          <div class="col-sm-10">
            <label
              for="secondaryProductCode"
              class="required form-label col-sm-10"
              >เลือก Warehouse</label
            >
            <select
              v-model="form.warehouseId"
              class="form-select fadeIn fifth"
              aria-label="Default select example"
            >
              <option
                v-for="item in warehouseItems"
                :key="item"
                :value="item.id"
              >
                {{ item.warehouse_code }} - {{ item.warehouse_short_name }} -
                {{ item.warehouse_full_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="row d-flex justify-content-center mb-4">
          <div class="col-sm-10">
            <label
              for="secondaryProductCode"
              class="required form-label col-sm-10"
              >เลือก Van</label
            >
            <select
              v-model="form.vanId"
              class="form-select fadeIn fifth"
              aria-label="Default select example"
            >
              <option v-for="item in vanItems" :key="item" :value="item.id">
                {{ item.van_code }} - {{ item.van_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="row d-flex justify-content-center mb-4">
          <div class="col-sm-10">
            <label
              for="secondaryProductCode"
              class="required form-label col-sm-10"
              >สเตตัส อนุมัติ</label
            >
            <select
              v-model="form.status"
              class="form-select fadeIn fifth"
              aria-label="Default select example"
            >
              <option value="ลงทะเบียนแล้ว">ลงทะเบียนแล้ว</option>
              <option value="ยังไม่ได้ลงทะเบียน">ยังไม่ได้ลงทะเบียน</option>
            </select>
          </div>
        </div>
        <!-- <div class="row d-flex justify-content-center">
          <label for="selectMainProduct" class="form-label col-sm-10"
            >เลือกกลุ่มสินค้าลัก</label
          >
          <div class="col-sm-10">
            <select
              class="form-select"
              v-model="form.productGroupId"
              :style="
                isSubmit
                  ? `border-color: #ced4da;padding-right: 0.75rem;background: none;${ccsSelect}`
                  : ''
              "
            >
              <option v-for="item in typeProduct" :key="item" :value="item.id">
                {{ item.code }} | {{ item.name }}
              </option>
            </select>
          </div>
        </div> -->
      </div>

      <hr class="pt-0 mt-0" style="color: LightGrey" />

      <footer class="m-6">
        <div class="card-toolbar row justify-content-center">
          <div class="col-sm-10">
            <button
              type="submit"
              class="btn btn-sm btn-light btn-primary ms-3 me-2"
              style="font-size: 14px; font-weight: bold"
              @click="submit"
              :disabled="loading"
            >
              <div
                v-if="loading"
                class="spinner-grow spinner-grow-sm text-light"
                role="status"
              ></div>
              {{ $t("save") }}
            </button>
            <button
              type="button"
              class="btn btn-sm btn-light"
              style="font-size: 14px; font-weight: bold"
              @click="cancel"
            >
              {{ $t("cancel") }}
            </button>
          </div>
        </div>
      </footer>
    </form>
  </div>
</template>

<script>
import { Decode, Encode } from "@/services";
import userApi from "@/api/user/";
import whApi from "@/api/warehouse/";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  setup() {
    document.title = "J&N | เพิ่มประเภทสินค้า";
  },
  data: () => ({
    loading: false,
    isSubmit: false,

    form: {
      code: "",
      name: "",
      selectMainProduct: "",
      status: "1",
      //
      username: "",
      password: "",
      email: "",
      firstName: "",
      lastName: "",
      telNumber: "",
      role: "",
      companyId: "",
      warehouseId: "",
    },

    ccsSelect: `background-size: 16px 12px;background-position: right 1rem center;background-repeat: no-repeat;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")`,

    typeProduct: [],
    companyItems: [],
    branchItems: [],
    warehouseItems: [],
    vanItems: [],
    userGroupItems: [],
    roleItems: [
      { code: "SUPER_ADMIN", name: "SUPER_ADMIN" },
      { code: "ADMIN", name: "ADMIN" },
      { code: "EMP_1", name: "พนักงาน1" },
      { code: "EMP_2", name: "พนักงาน2" },
      { code: "EMP_3", name: "พนักงาน3" },
    ],
  }),
  watch: {
    "form.companyId"(val) {
      console.log("form.companyId", val);
      this.getAllBranch(val);
    },
    "form.branchId"(val) {
      this.getAllWarehouse(val);
      this.getAllVan(val);
    },
  },
  created() {
    // this.getOne();
    this.getAllCompany();
    this.getAllUserGroup();
    this.getAllWarehouse();
    this.getAllVan();
  },

  methods: {
    async getAllUserGroup(method) {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await userApi.usertablet.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.userGroupItems = responseData.data;
        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    // async getOne() {
    //   this.loading = true;
    //   this.$route.query.id;
    //   let responseData = [];
    //   try {
    //     responseData = await userApi.usertablet.getOne(this.$route.query.id);
    //   } catch (error) {
    //     console.log(error);
    //   }
    //   if (responseData.response_status === "SUCCESS") {
    //     this.form = responseData.data;
    //     this.loading = false;
    //   } else {
    //     this.loading = false;
    //     Swal.fire({
    //       icon: "error",
    //       title: "เกิดข้อผิดพลาด",
    //       text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
    //     });
    //   }
    // },
    async getAllCompany() {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await userApi.company.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.companyItems = responseData.data;
      }
      this.loading = false;
    },
    async getAllBranch(companyId) {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await whApi.branch.getAll(companyId);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.branchItems = responseData.data;
      }
      this.loading = false;
    },
    async getAllWarehouse(branchId) {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await whApi.warehouse.getAll(branchId);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.warehouseItems = responseData.data;

        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    async getAllVan(branchId) {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await whApi.van.getAll(branchId);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.vanItems = responseData.data;

        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    async submit() {
      let createResponse = [];

      await this.validateForm();
      await this.checkFormIsEmpty();
      this.isSubmit = true;

      if (this.isValid) {
        this.loading = true;

        this.form = {
          ...this.form,
          // companyId: parseInt(localStorage.getItem("companyId")),
        };

        createResponse = await userApi.usertablet.create(this.form);
        if (createResponse.response_status === "SUCCESS") {
          Swal.fire({
            icon: "success",
            title: `${this.$t("addsuccess")} `,
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            this.$router.push({
              path: "/config/user-tablet/index",
              query: {},
            });
          });
          this.loading = false;
        } else {
          Swal.fire({
            icon: "error",
            title: `${createResponse.message}`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.loading = false;
        }
      }
    },

    checkFormIsEmpty() {
      !this.form.name
        ? (this.isValid = false)
        : !this.form.companyId
        ? (this.isValid = false)
        : !this.form.branchId
        ? (this.isValid = false)
        : !this.form.warehouseId
        ? (this.isValid = false)
        : !this.form.vanId
        ? (this.isValid = false)
        : !this.form.status
        ? (this.isValid = false)
        : (this.isValid = true);
    },

    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    cancel() {
      this.$router.push({
        path: "/config/user-tablet/index",
        query: {},
      });
    },
  },
};
</script>
